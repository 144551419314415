import * as React from "react";

import PageBlock from "../../components/Layout/PageBlock";
import PageLayout from "../../components/Layout/PageLayout";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { StaticImage } from "gatsby-plugin-image";

import { ArrowRight } from "react-feather";
import { CTALink, InlineExternalLink, PageBlockNextPageDivider, primaryColour } from "../../styles/component-stylings";

const PAGE_TITLE = "Partners";
const EXT_LINK = "https://www.afp.gov.au/about-us/publications-and-reports/australian-criminal-intelligence-model-acim-strategy";

const PartnersPage = ({ pageContext }) => {
    return (
        <PageLayout pageTitle={PAGE_TITLE} pageContext={pageContext}>
            <PageBlock pageContext={pageContext} backgroundColour='white' isFirstBlock>
                <Breadcrumb pageContext={pageContext} />
                <h1>{PAGE_TITLE}</h1>
                <StaticImage src='../../images/afp-officer.jpg' alt='AFP Officer' placeholder='blurred' loading='lazy' />
                <p>DSC and the AFP work in tandem with many national and international entities.</p>
                <p>The AFP is part of the National Criminal Intelligence Capability Committee (NCICC).</p>
                The NCICC is comprised of heads of intelligence from the following agencies:
                <ul>
                    <li>Attorney General's Department</li>
                    <li>Australian and New Zealand Policing Advisory Agency</li>
                    <li>Australian Criminal Intelligence Commission</li>
                    <li>Australian Federal Police (AFP)</li>
                    <li>ACT Policing</li>
                    <li>Australian Security and Investments Commission</li>
                    <li>Australian Taxation Office</li>
                    <li>Australian Transaction Reports and Analysis Centre</li>
                    <li>Department of Immigration and Border Protection / Australian Border Force (DIBP)</li>
                    <li>New South Wales Police</li>
                    <li>New Zealand Police</li>
                    <li>Northern Territory Police</li>
                    <li>Queensland Police</li>
                    <li>South Australia Police</li>
                    <li>Tasmania Police</li>
                    <li>Victoria Police</li>
                    <li>Western Australia Police</li>
                </ul>
                <p>The Australian Criminal Intelligence Modal (ACIM) is an initiative of the NCICC.</p>
                <p>
                    You can learn more about the ACIM{" "}
                    <InlineExternalLink href={EXT_LINK} target='_blank' rel='external'>
                        here
                    </InlineExternalLink>
                    .
                </p>
                <PageBlockNextPageDivider>
                    <CTALink $invert $borderColour={primaryColour} to='/the-work' rel='next'>
                        <span>The work</span>
                        <ArrowRight />
                    </CTALink>
                </PageBlockNextPageDivider>
            </PageBlock>
        </PageLayout>
    );
};

export default PartnersPage;
